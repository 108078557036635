import React, { useEffect, useState }                from 'react';
import moment                from 'moment';
import { ShowController }    from 'react-admin';
import { UserProvider }      from '../../../Providers';

import 'moment/locale/es';

import { Show }  from './components';

moment.locale('es');

const UserShow = (props) => {
    const [statusLogs, setStatusLogs] = useState([]);
    useEffect(() => {(async () => {
        try {
            const data = await UserProvider.fetchStatusLogs(props.id);
            setStatusLogs(data.data);
        }
        catch (e) {
            console.error(e)
        }
    })()}, []);

    return (
        <ShowController
            {...props}
            title={`Usuario ${props.id}`}
        >
            {controllerProps => <Show statusLogs={statusLogs} {...(controllerProps || {})} />}
        </ShowController>
    );
}

export default UserShow;
