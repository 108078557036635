const styles = {
  container: {
    marginLeft: -10,
    marginTop: 20,
  },
  titleProvider: {
    color: "#FFFFFF",
    marginBottom: 40,
    fontSize: 20,
    marginTop: 60
  },
};

export default styles;
