import React, { useState } from 'react';
import { Dialog, DialogContent, TextField, Button, Typography } from '@material-ui/core';
import Swal from 'sweetalert2';
import { BusinessesProvider } from '../../../../Providers';
import { TO_NUMBER, toMoney } from '../../../../Helpers/MoneyHelper';
import { CircularProgress } from '@material-ui/core';

const AddBalanceDialog = (props) => {

  const { open, handleClose, handleRefresh, record } = props;

  const [amount, setAmount] = useState(0);
  const [description, setDescription] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleAmountChange = (event) => {
    const newValue = TO_NUMBER(
			event.target.value || '0',
			record.attributes.default_currency
		);

    setAmount(newValue);

    if(newValue > 0){
      setIsValid(description.trim() !== '');
    }
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
    if(event.target.value.trim() !== ''){
      setIsValid(amount > 0);
    }
  };

  const handleSubmit = async () => {
    if(isValid){
        const result = await Swal.fire({
          title: 'Revise los datos de la recarga antes de continuar',
          html: `<strong>Monto: </strong> ${toMoney(amount, record.attributes.default_currency)}
          ${record.attributes.default_currency}<br/>
          <strong>Cuenta: </strong> ${record.attributes.email} <br/>
          <strong>Descripción: </strong> ${description} <br/>`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar'
        });

        if(result.isConfirmed){
          setIsLoading(true);
          try {
            await BusinessesProvider.addBalance(
              record.attributes.email,
              record.attributes.default_currency.toLowerCase(),
              amount,
              'business',
              description
            );

            Swal.fire({
              title: 'Recarga exitosa',
              text: '',
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Entendido'
            });
            setIsLoading(false);
            handleClose();
            handleRefresh();
          } catch (error) {
            setIsLoading(false);
            Swal.fire({
              title: 'Tuvimos un error procesando la recarga',
              text: error?.message || 'Algo no anda bien, revisa los datos e intenta nuevamente',
              icon: 'error',
              showCancelButton: false,
              confirmButtonText: 'Reintentar'
            });
          }
        }
    }else{
      Swal.fire({
        title: 'No es posible continuar',
        text: 'Asegúrate de llenar el formulario correctamente antes de continuar.',
        icon: 'error',
        showCancelButton: false,
        confirmButtonText: 'Reintentar'
      })
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogContent>
      <Typography component="div">
        {`Recarga para ${record?.attributes?.first_name || ''} ${record?.attributes?.last_name || ''}`}
      </Typography>
      </DialogContent>
      <DialogContent>
        <form>
          <TextField
            autoFocus
            margin='dense'
            id='amount'
            label='Monto'
            fullWidth
            value={toMoney(amount, record.attributes.default_currency)}
            required
            onChange={handleAmountChange}
          />
          <TextField
            margin='dense'
            id='description'
            label='Descripción'
            type='text'
            fullWidth
            multiline
            rows={1}
            value={description}
            required
            onChange={handleDescriptionChange}
          /> 
        </form>
      </DialogContent>
      <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '16px' }}>
        <Button disabled={isLoading} onClick={handleClose} color='secondary'>
          Cancelar
        </Button>
        <Button disabled={!isValid} onClick={handleSubmit} color='primary' variant='contained' style={{ marginLeft: '16px' }}>
          {
            isLoading ?
            <CircularProgress size={20} color={'secondary'}/> :
            'Continuar'
          }
        </Button>
      </div>
    </Dialog>
  );
};

export default AddBalanceDialog;
