import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Grid,
  TextField,
  Card,
  Typography,
  CardContent,
  MenuItem
} from '@material-ui/core';

import { FormTypeProvider } from '../../../../../Providers';
import OtherCard from '../OtherCard';
import styles from './styles';

import PropTypes from 'prop-types';

const PersonalInformation = (props) => {
  const { record: { attributes }, formType } = props;
  const [formData, setFormData] = useState(attributes);
  const [formSections, setFormSections] = useState([]);
  const [depOptions, setDepOptions] = useState({});
  const firstRender = useRef(false);
  const depRefFields = useRef({});

  let dependent_fields = [];
  const formFields = useMemo(() => {
    if (!formSections.data) return [];

    const { data } = formSections;
    dependent_fields = [];

    data.forEach(section => {
      section.fields.forEach(field => {
        field.dependent_field && field.dependent_field.forEach(field_name_key => {
          dependent_fields.push({ name: field_name_key, value: formData[field.name_key] });
        });
      });
    });

    const fields = data.map(section => {
      section.fields.map(field => {
        dependent_fields.forEach(async dependent_field => {
          if (field.name_key === dependent_field.name && dependent_field.value && depOptions[field.name_key]) {
            field.options = depOptions[field.name_key];
          }
        });
        return field;
      })
      return section;
    });

    return fields;
  }, [formData, formSections, depOptions]);

  useMemo(() => {
    if (!formSections.data) return () => null;
    const { data } = formSections;

    data.forEach(section => {
      section.fields.forEach(field => {
        dependent_fields.forEach(async dependent_field => {
          if (
            field.name_key === dependent_field.name &&
            dependent_field.value &&
            depRefFields.current[dependent_field.name] !== dependent_field.value
          ) {
            const responseField = await FormTypeProvider.fetchFilterOptions(`?form_field_id=${field.id}&dependent_option=${dependent_field.value}`);
            depRefFields.current = {
              ...depRefFields.current,
              [dependent_field.name]: dependent_field.value
            };
            setDepOptions({
              ...depOptions,
              [field.name_key]: responseField.data
            })
          }
        });
      })
    });
  }, [formSections, formData]);

  useEffect(() => {
    (async () => {
      if (Object.keys(formData).length > 0 && !firstRender.current) {
        const { user: { id: userID } } = formData;
        const sections = await FormTypeProvider.fetchFilterFormType(`?user_id=${userID}&form_type=${formType.toLowerCase()}`);
        setFormSections(sections);
        firstRender.current = true;
      }
    })();
  }, [formData]);

  const validateTypeField = field => field.type === 'text' || field.type === 'email' || field.type === 'numeric' || field.type === 'date';

  const validateShowField = field => {
    if (field.hasOwnProperty('visible'))
      return formData[field.visible.key] === field.visible.value;
    else
      return true;
  };

  return (
    <Card style={styles.card}>
      {formFields.length > 0 ?
        (formFields.map(section => (
          <CardContent key={section.section.id}>
            <Typography
              variant="body1"
              gutterBottom
              style={styles.titleWhite}
              key={section.section.id}
            >
              {section.section.label}
            </Typography>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={styles.divisorTop}
            >
              {section.fields.map(field => (
                (validateShowField(field) ?
                  (validateTypeField(field) ?
                    (<Grid
                      style={styles.divisor}
                      item
                      xs={12}
                      sm={5}
                      key={field.id}
                    >
                      <TextField
                        id={field.name}
                        name={field.name}
                        label={field.label}
                        value={formData[field.name_key]}
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>)
                    :
                    (<Grid
                      style={styles.divisor}
                      item
                      xs={12}
                      sm={5}
                      key={field.id}
                    >
                      <TextField
                        id={field.name}
                        name={field.name}
                        label={field.label}
                        select
                        value={formData[field.name_key]}
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                      >
                        {field.options.map(option => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>)
                  )
                  : null)
              ))}
            </Grid>
          </CardContent>
        ))) : null
      }
      { formType=='card' && <OtherCard {...props}/>}
    </Card>
  )
};

PersonalInformation.propTypes = {
  formType: PropTypes.string
}

PersonalInformation.defaultProps = {
  formType: "card"
}

export default PersonalInformation;