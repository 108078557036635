import React, { useState } from 'react';
import {
    Edit,
    Toolbar,
    SaveButton,
    TabbedForm,
    FormTab,
    FormDataConsumer,
} from 'react-admin';
import TransactionLimits from './Components/TransactionLimits';
import CurrenciesTable from './Components/Currencies';
import AutomaticPaymentRoutesPanel from './Components/AutomaticPaymentRoutesPanel';
import { GeneralSettingsTab, ProvidersRecharge} from './Components';
import useProvidersRecharges from '../../../hooks/useProvidersRecharges';

const CountryEditToolbar = (props) => (<Toolbar {...props} >{!props.hidden && (<SaveButton disabled={props.isMaxLengthMessageTime}/>)} </Toolbar>);

const CountryEdit = (props) => {
    const [isValidMaxMessage, setIsValidMaxMessage] = useState(false);
    const {
        providersList,
        isOpenModal,
        itemUpdate,
        image_design,
        isUpdate,
        hidden,
        isRechargeStatus,
        tabState,
        validateModalRoute,
        isGlobal,
        showImage,
        getProvidersRecharge,
        handleUpdateProvider,
        handleOpenModal,
        handleCloseModal,
        handleOnchange,
        setImage_design,
        isAccess,
        setIsRechargeStatus,
        changeTab,
        editHide,
    } = useProvidersRecharges(props.id)

    const LimitsTab = () => {
        return (
            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    <TransactionLimits
                        {...formData}
                        {...rest}
                    />
                )}
            </FormDataConsumer>
        )
    }

    const CurrenciesTab = () => {
        return (
            <CurrenciesTable />
        )
    }

    const validateCountryInformation = (values) => {
        // Para futuras validaciones de los campos
        const errors = {};
        if (values.attributes && values.attributes.message_time_withdrawal && values.attributes.message_time_withdrawal.length > 50) {
            setIsValidMaxMessage(true)
        }
        if (isValidMaxMessage && values.attributes && values.attributes.message_time_withdrawal && values.attributes.message_time_withdrawal.length <= 50) {
            setIsValidMaxMessage(false)
        }
        return errors
    };

    return (
      <Edit
        title={`Editar país ${props.id}`}
        actions={null}
        {...props}
      >
            <TabbedForm toolbar={<CountryEditToolbar hidden={hidden} isMaxLengthMessageTime={isValidMaxMessage}/>} validate={validateCountryInformation}>
            <FormTab 
                label="Configuración general" 
                style={{ color: 'white' }} 
                onClick={() => editHide(false)}
            >
                <GeneralSettingsTab 
                    {...props}
                    setIsRechargeStatus={setIsRechargeStatus}
                    validateModalRoute={validateModalRoute}
                />
            </FormTab>
            <FormTab
                label='Límites por destino'
                style={{ color: 'white' }}
                onClick={() => editHide(true)}
            >
                <LimitsTab />
            </FormTab>
            <FormTab 
                label="Proveedores" 
                style={{ color: 'white' }} 
                onClick={() => editHide(true)}
            >
                <ProvidersRecharge 
                    changeTab = {changeTab} 
                    tabState = {tabState} 
                    providersList = {providersList}
                    isOpenModal = {isOpenModal}
                    itemUpdate = {itemUpdate}
                    image_design = {image_design}
                    isUpdate = {isUpdate}
                    getProvidersRecharge = {getProvidersRecharge}
                    handleUpdateProvider = {handleUpdateProvider}
                    handleOpenModal = {handleOpenModal}
                    handleCloseModal = {handleCloseModal}
                    showImage = {showImage}
                    setImage_design = {setImage_design}
                    handleOnchange = {handleOnchange}
                    isGlobal = {isGlobal}
                    editHide={editHide}
                    isRechargeStatus={isRechargeStatus}
                    {...props}
                />
            </FormTab>
            <FormTab
                label='Monedas'
                style={{ color: 'white' }}
                onClick={() => editHide(true)}
            >
                <CurrenciesTable />
            </FormTab>
            {isAccess('config_automatic_payments') && (
                <FormTab
                    label='Pagos automáticos'
                    style={{ color: 'white' }}
                    onClick={() => editHide(true)}
                >
                    <AutomaticPaymentRoutesPanel country_id={props.id}/>
                </FormTab>
            )}
        </TabbedForm>
      </Edit>
    );
};

export default CountryEdit;
