import React, {useState, useCallback, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField    from '@material-ui/core/TextField';
import {Edit} from 'react-admin';

import UserProvider from '../../../Providers/Users';
import BankMovementsProvider from '../../../Providers/BankMovements';

import styles from './styles';
import moment from "moment";
import {toMoney} from "../../../Helpers/MoneyHelper";
import { useAccessRole, useAlerts } from '../../../hooks';
import Swal from 'sweetalert2'

const BankMovementsEdit = (props) => {
    const {history} = props;
    const [isFetch, setFetch] = useState(false);
    const [users, setUsers] = useState(null);
    const [isUserNotFound, setUserNotFound] = useState(false);
    const [queryValue, setQueryValue] = useState('');
    const [isCreate, setCreate] = useState(false);
    const [isDelete, setDelete] = useState(false);
    const [recordActive, setRecordActive] = useState(null);
    const [userActive, setUserActive] = useState(null);
    const [isSuccessful, setSuccessful] = useState(false);
    const { isAccess } = useAccessRole();
    const { errorMessage, successMessage } = useAlerts();

    useEffect(() => {
        if (isSuccessful) {
            setTimeout(() => {
                history.push('/bank_movements?filter={"is_bank_transfers_unassigned"%3Atrue}');
            }, 3000)
        }
    }, [isSuccessful]);

    const matchDocuments = (user) => {
        const bankMovementSenderDocumentNumber = user?.record?.sender_holder_id?.trim()?.toLowerCase();
        const foundUserDocumentNumber = user?.document_number?.trim()?.toLowerCase()
        const bankMovementSource = user?.record?.source;

        if(bankMovementSource === 'stp'){
            // STP needs to match only first 10 characters;
            return bankMovementSenderDocumentNumber?.slice(0, 10) === foundUserDocumentNumber?.slice(0, 10);
        }else{
            return bankMovementSenderDocumentNumber === foundUserDocumentNumber;
        }
    }

    const getVitaUser = async (record) => {
        try {
            setFetch(true);
            const emailValue = encodeURIComponent(queryValue.toLowerCase().trim());
            const idValue = queryValue.trim();
            let query = '';
            if(record?.source === 'stp'){
                // STP needs to match only first 10 characters;
                const documentNumberValue = queryValue.trim().slice(0,10);
                query = `?q[m]=or&q[email_eq]=${emailValue}&q[id_eq]=${idValue}&q[document_number_start]=${documentNumberValue}`
            }else{
                const documentNumberValue = queryValue.trim();
                query = `?q[m]=or&q[email_eq]=${emailValue}&q[id_eq]=${idValue}&q[document_number_eq]=${documentNumberValue}`
            }

            const response = await UserProvider.getVitaUser(query);
            setUsers(response.data.users.data);
            setUserNotFound(false);
        } catch {
            setUserNotFound(true);
        }
        setFetch(false);
    };

    const deleteBankMovement = useCallback(async () => {
        try {
            await BankMovementsProvider.deleteBankMovement(recordActive.external_id);
            setSuccessful(true);
        } catch {}
    }, [recordActive]);

    const assignRecharge = useCallback(async () => {
        try {
            await BankMovementsProvider.assignBankMovementsRecharge(userActive.id, recordActive.external_id, recordActive.amount);
            setSuccessful(true);
        } catch (error) {
            errorMessage(error.data.error.message);
        }
    }, [userActive, recordActive]);

    const mapHumanVerificationStatus = (profile_verification_status) => {
        const humanStatus = {
            initial: 'Sin verificación',
            started: 'Iniciada',
            accepted: 'Aceptada',
            rejected: 'Rechazada',
            incomplete: 'Incompleta',
            verification_required: 'Actualización pendiente',
            verification_profile_completed: 'Actualización completada (Revisión pendiente)',
            profile_update_alerted: 'Alerta de Actualización'
        }

        return humanStatus[profile_verification_status];
    };

    const UserInfo = (user) => {
        const {record, first_name, last_name, email, default_currency, profile_verification, document_number, document_type, assign = false, currency_name} = user;
        return (
            <div style={styles.container}>
                <div style={{width: '100%'}}>
                    <div style={styles.content}>
                        <div style={{width: '50%'}}>
                            <div style={styles.subtitle}>Nombres</div>
                            <div style={styles.info}>{first_name} {last_name}</div>
                        </div>
                        <div style={{width: '50%'}}>
                            <div style={styles.subtitle}>Correo</div>
                            <div style={styles.info}>{email}</div>
                        </div>
                    </div>
                    <div style={styles.content}>
                        <div style={{width: '50%'}}>
                            <div style={styles.subtitle}>Tipo de documento</div>
                            <div style={styles.info}>{document_type}</div>
                        </div>
                        <div style={{width: '50%'}}>
                            <div style={styles.subtitle}>Numero de documento</div>
                            <div style={styles.info}>{document_number}</div>
                        </div>
                    </div>
                    <div style={styles.content}>
                        <div style={{width: '50%'}}>
                            <div style={styles.subtitle}>Verificacion</div>
                            <div style={styles.info}>{mapHumanVerificationStatus(profile_verification)}</div>
                        </div>
                        <div style={{width: '50%'}}>
                            <div style={styles.subtitle}>Moneda</div>
                            <div style={styles.info}>{currency_name}</div>
                        </div>
                    </div>
                    <div style={styles.content}>
                        {!assign && default_currency.toLowerCase() === record.currency.toLowerCase() && 
                            [
                                'started',
                                'accepted',
                                'verification_required',
                                'verification_profile_completed',
                                'profile_update_alerted'
                            ].includes(profile_verification) &&
                            matchDocuments(user) &&
                            (
                            <div style={{width: '50%'}}>
                                <Button size="small" style={styles.search_button} onClick={() => {
                                    setCreate(true);
                                    setUserActive(user);
                                    setRecordActive(record);
                                }}>
                                    ASIGNAR RECARGA
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const Form = (EditProps) => {
        const {record = null} = EditProps;
        const [document, setDocument] = useState(record && record.sender_holder_id)

      const assignNewDocument = useCallback(async () => {
        try {
            if (record.sender_holder_id !== document){
                const response = await BankMovementsProvider.assignNewIdentification(record.id, document);
                if (response && response.data.message) {
                  Swal.fire({
                    title: "¡ÉXITO!",
                    text: response.data.message,
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK'
                  }).then((result) => {
                    if (result.isConfirmed) {
                      window.location.reload();
                    }
                  })
                }
            }
        } catch (error) {
            errorMessage(error.data.message);
        }
      }, [document, record]);

        if (isSuccessful) {
            return (
                <div style={styles.container}>
                    <div style={{color: '#FFFFFF', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{color: '#FFFFFF', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                            ¡La operación se realizó con exito!
                        </div>
                    </div>
                </div>
            );
        }

        if (isCreate) {
            return (
                <div style={styles.container}>
                    <div style={{color: '#FFFFFF', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <UserInfo {...userActive} assign />
                        <div style={{color: '#FFFFFF', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                            ¿Esta seguro que desea asignar esta recarga?
                            <div style={{paddingTop: 20}}>
                                <Button size="small" style={styles.search_button} onClick={assignRecharge}>
                                    ASIGNAR RECARGA
                                </Button>
                                <Button size="small" style={styles.cancel_button} onClick={() => {
                                    setCreate(false);
                                }}>
                                    CANCELAR
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        if (isDelete) {
            return (
                <div style={styles.container}>
                    <div style={{color: '#FFFFFF', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        ¿Esta seguro que desea eliminar esta recarga?
                        <div style={{paddingTop: 20}}>
                            <Button size="small" style={styles.delete_button} onClick={deleteBankMovement}>
                                BORRAR RECARGA
                            </Button>
                            <Button size="small" style={styles.cancel_button} onClick={() => {
                                setDelete(false);
                            }}>
                                CANCELAR
                            </Button>
                        </div>
                    </div>
                </div>
            );
        }

        if (record) {
          return (
                <div style={styles.container}>
                    <div style={{width: '50%'}}>
                        <div style={styles.title}>Información de la recarga</div>
                        <div style={styles.content}>
                            <div style={{width: '50%'}}>
                                <div style={styles.subtitle}>ID del movimiento</div>
                                <div style={styles.info}>{record.external_id}</div>
                            </div>
                            <div style={{width: '50%'}}>
                                <div style={styles.subtitle}>Fecha</div>
                                <div style={styles.info}>{moment.utc(record.transaction_date).format('LL')}</div>
                            </div>
                        </div>
                        <div style={styles.content}>
                            <div style={{width: '50%'}}>
                                <div style={styles.subtitle}>Tipo</div>
                                <div style={styles.info}>{record.transaction_type}</div>
                            </div>
                            <div style={{width: '50%'}}>
                                <div style={styles.subtitle}>Referencia</div>
                                <div style={styles.info}>{record.reference_id}</div>
                            </div>
                        </div>
                        <div style={styles.content}>
                            <div style={{width: '50%'}}>
                                <div style={styles.subtitle}>Nombre del titular</div>
                                <div style={styles.info}>{record.sender_holder_name}</div>
                            </div>
                            <div style={{width: '50%'}}>
                                <div style={styles.subtitle}>Identificación del titular</div>
                                {/* <div style={styles.info}>{record.sender_holder_id}</div> */}
                                <input
                                    onBlur={() => isAccess('edit_document_banks_movements_admin')
                                        ? assignNewDocument()
                                        : errorMessage('No posee permisos para esta operación')}
                                    value={document}
                                    onChange={e => setDocument(e.target.value)}
                                />
                            </div>
                        </div>
                        <div style={styles.content}>
                            <div style={{width: '33.33%'}}>
                                <div style={styles.subtitle}>Monto</div>
                                <div style={styles.info}>{toMoney(
                                    record.amount,
                                    record.currency
                                )}</div>
                            </div>
                            <div style={{width: '33.33%'}}>
                                <div style={styles.subtitle}>Total</div>
                                <div style={styles.info}>{toMoney(
                                    record.total,
                                    record.currency
                                )}</div>
                            </div>
                            <div style={{width: '33.33%'}}>
                                <div style={styles.subtitle}>Moneda</div>
                                <div style={styles.info}>{record.currency}</div>
                            </div>
                        </div>
                        <div style={styles.content}>
                            <div style={{width: '33.33%'}}>
                                <div style={styles.subtitle}>Tipo de comisión</div>
                                <div style={styles.info}>{record.fee_type === 'amount' ? 'Monto' : 'Porcentaje'}</div>
                            </div>
                            <div style={{width: '33.33%'}}>
                                <div style={styles.subtitle}>Comisión</div>
                                <div style={styles.info}>{record.fee_value} {record.fee_type === 'amount' ? '' : '%'}</div>
                            </div>
                            <div style={{width: '33.33%'}}>
                                <div style={styles.subtitle}>Total de la comisión</div>
                                <div style={styles.info}>{record.total_fee}</div>
                            </div>
                        </div>
                        <div style={styles.content}>
                            <div style={{width: '100%'}}>
                                <div style={styles.subtitle}>Descripción</div>
                                <div style={styles.info}>{record.description}</div>
                            </div>
                        </div>
                        <div style={styles.content}>
                            <div style={{width: '100%'}}>
                                <div style={styles.subtitle}>Razón de la no asignación</div>
                                <div style={styles.info}>{record.detail_nonassignment}</div>
                            </div>
                        </div>
                    </div>
                    <div style={styles.buttons_content}>
                        {isFetch ? (
                            <div>
                                <div style={styles.subtitle}>Cargando información del usuario Vita...</div>
                            </div>
                        ) : (
                            <div>
                                {users === null ? (
                                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                        <TextField
                                            label="Correo, id o documento"
                                            value={queryValue}
                                            onChange={(event) => setQueryValue(event.target.value)}
                                            autoFocus
                                        />
                                        {queryValue !== '' && (
                                            <div style={{paddingTop: 10}}>
                                                <Button size="small" style={styles.search_button} onClick={async () => await getVitaUser(record)}>
                                                    BUSCAR USUARIO
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        {isUserNotFound ? (
                                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                                <div style={styles.title}>El usuario no existe</div>
                                                <Button size="small" style={styles.delete_button} onClick={() => {
                                                    setDelete(true);
                                                    setRecordActive(record);
                                                }}>
                                                    BORRAR RECARGA
                                                </Button>
                                            </div>
                                        ) : (
                                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                                <Button size="small" style={styles.delete_button} onClick={() => {
                                                    setDelete(true);
                                                    setRecordActive(record);
                                                }}>
                                                    BORRAR RECARGA
                                                </Button>
                                                <div style={{paddingTop: 20, paddingBottom: 20}}>
                                                    {users.length > 0 ? (
                                                        <div>
                                                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                                                <div style={styles.title}>Información del usuario Vita</div>
                                                            </div>
                                                            {users.map((user) => {
                                                                return <UserInfo record={record} id={user.id} {...user.attributes} />
                                                            })}
                                                        </div>
                                                    ) : (
                                                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                                            <div style={styles.title}>No se encontraron usuarios</div>
                                                        </div>
                                                    )}
                                                </div>
                                                <Button size="small" style={styles.cancel_button} onClick={() => {
                                                    setUsers(null);
                                                }}>
                                                    VOLVER
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            );
        }

        return null;
    };

    return (
        <Edit
            title={`Editar movimiento ${props.id}`}
            {...props}
        >
            <Form />
        </Edit>
    );
};

export default BankMovementsEdit;