import { useEffect, useState } from "react";
import Swal from "sweetalert2";

import { UserProvider } from "../Providers";
import {
  PAYMENT_ORDERS_SERVICE_STATUSES,
  modalCopyValues,
  downloadFromUrl,
} from "../Utils";
import useReset from "./useReset";
import useAlerts from "./useAlerts";
import useAccessRole from "./useAccessRole";

const useUserBusinessLink = (props) => {
  const { resetList } = useReset();
  const { errorMessage, successMessage, showModal } = useAlerts();
  const { isAccess } = useAccessRole();

  const {
    record: {
      id,
      attributes: { balances, payment_orders_service_status, default_currency },
    },
  } = props;

  // 1 Datos generales
  const [hasInfo, setHasInfo] = useState(false);
  const [image_design, setImage_design] = useState([]);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [configUser, setconfigUser] = useState({
    webHook: "",
    logoCompany: "",
    timePaymentLink: 1,
    timePaymentList: [],
    currencyPayment: "",
    CurrencyPaymentList: Object.keys(balances) || [],
    status: "",
  });
  const [configUserUpdate, setconfigUserUpdate] = useState({
    webHook: "",
    logoCompany: "",
    timePaymentLink: 1,
    timePaymentList: [],
    currencyPayment: "",
    CurrencyPaymentList: [],
    status: "",
  });

  const handleOnchangeConfigUser = (e) => {
    setconfigUserUpdate({
      ...configUserUpdate,
      [e.target.name]: e.target.value,
    });
  };

  const handleCloseModalInfo = () => {
    setconfigUserUpdate({
      webHook: "",
      logoCompany: "",
      timePaymentLink: 1,
      timePaymentList: [],
      currencyPayment: "",
      CurrencyPaymentList: [],
      status: "",
    });
    setIsOpenModalInfo(false);
  };

  const handleUpdateConfigUser = () => {
    setconfigUserUpdate(configUser);
    setIsOpenModalInfo(true);
  };

  const updateConfigUser = async () => {
    const isAllowed = isAccess('edit_business_payment_order_config')
    if (!isAllowed) errorMessage('No tienes permisos para acceder a esta sección')

    try {
      const formData = new FormData();

      formData.append(
        "payment_order_notification_webhook_url",
        configUserUpdate.webHook || ''
      );
      formData.append(
        "payment_order_expiration_time",
        configUserUpdate.timePaymentLink
      );
      formData.append(
        "payment_order_currency_destiny_iso_code",
        configUserUpdate.currencyPayment?.toUpperCase() || default_currency
      );

      const status = PAYMENT_ORDERS_SERVICE_STATUSES.filter(
        (item) => item.id === configUserUpdate.status
      );

      if (status.length) {
        formData.append("payment_orders_service_status", status[0].id);
      }

      if (image_design.length) {
        formData.append("logotype_image", image_design[0]);
      }

      await UserProvider.updateInfoPaymentLinkUserBusiness(id, formData);

      resetList();
    } catch (error) {
      errorMessage("Error al actualizar la información");
    }
  };

  const handleDownloadLogoUser = () => {
    try {
      downloadFromUrl(configUser.logoCompany, "logoCompany");
    } catch (error) {}
  };

  const handleShowImage = () => {
    Swal.fire({
      position: "center",
      heightAuto: true,
      imageUrl: URL.createObjectURL(image_design[0]),
      text: "Imagen Cargada Correctamente.",
      width: 350,
      imageWidth: 200,
      imageHeight: 200,
      imageAlt: "Error",
    });
  };

  // 2 button
  const [disableButton, setdisableButton] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [providerUpdate, setProviderUpdate] = useState({
    id: 0,
    country: "",
    spread: 0,
    fixedCost: 0,
    nameProvider: "",
  });
  const [dataProvider, setDataProvider] = useState({
    listCountries: [],
    listProviders: [],
  });

  const [providersAvalaible, setProvidersAvalaible] = useState({});

  const getProviderActives = async (idCountry) =>
    setDataProvider((prev) => ({
      ...prev,
      listProviders: providersAvalaible[idCountry].map((provider) => ({
        id: provider.id,
        public_name: provider.code,
      })),
    }));

  const getCountriesAvailable = async (countries) =>
    setDataProvider((prev) => ({
      ...prev,
      listCountries: countries.map((country) => ({
        id: country,
        name: country,
      })),
    }));

  const handleOnchangeItemUpdate = ({ target }) => {
    if (target.name === "country") {
      getProviderActives(target.value);
    }

    setProviderUpdate({
      ...providerUpdate,
      [target.name]: target.value,
    });
  };

  const handleAssignNewProvider = async () => {
    const isAllowed = isAccess('create_user_payment_order_providers')
    if (!isAllowed) errorMessage('No tienes permisos para acceder a esta sección')

    try {
      const data = {
        fixed_cost: providerUpdate.fixedCost,
        spread: providerUpdate.spread,
        payment_order_provider_id: providerUpdate.nameProvider,
      };

      await UserProvider.assignProviderPaymentAvailableUserBusiness(id, data);

      resetList();
    } catch (error) {
      errorMessage(error.data.message);
    }
  };

  const handleCloseModal = () => {
    setProviderUpdate({
      id: 0,
      country: "",
      spread: 0,
      fixedCost: 0,
      nameProvider: "",
    });

    setIsOpenModal(false);
  };

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };

  // 3 Proveedores habilitados
  const [listProvidersActives, setListProvidersActives] = useState([]);

  const handleUpdateProviderActive = async (provider) => {
    setIsOpenModal(true);
    setIsUpdate(true);

    setProviderUpdate({
      id: provider.id,
      country: provider.country,
      spread: provider.spread,
      fixedCost: provider.fixed_cost,
      nameProvider: provider.nameProvider,
    });
  };

  const handleUpdateProviderItemUpdate = async () => {
    const isAllowed = isAccess('edit_user_payment_order_providers')
    if (!isAllowed) errorMessage('No tienes permisos para acceder a esta sección')

    try {
      const data = {
        fixed_cost: providerUpdate.fixedCost,
        spread: providerUpdate.spread,
      };

      await UserProvider.updateProviderPaymentActiveUserBusiness(
        id,
        providerUpdate.id,
        data
      );

      resetList();
    } catch ({ data }) {
      errorMessage(data.message);
    }
  };

  const handleDeleteProvider = async (idProvider) => {
    const isAllowed = isAccess('delete_user_payment_order_providers')
    if (!isAllowed) errorMessage('No tienes permisos para acceder a esta sección')

    await showModal();
    try {
      await UserProvider.deleteUserProviderPaymentActiveUserBusiness(
        id,
        idProvider
      );

      resetList();
    } catch ({ data }) {
      errorMessage(data.message);
    }
  };

  // 4 Enlaces generados
  const [listLinksUser, setListLinksUser] = useState([]);
  const [pageList, setPageList] = useState({
    count: 10,
    page: 0,
    total: 0,
  });

  const getLinksPayment = async ({
    page = pageList.page,
    count = pageList.count,
  }) => {
    const isAllowed = isAccess('show_business_payment_order_config')
    if (!isAllowed) errorMessage('No tienes permisos para acceder a esta sección')

    try {
      const { data: {data:{data}, total} } = await UserProvider.getBusinessPaymentOrders(
        id,
        page + 1,
        count
      );

      setPageList({
        total: total,
        page: page,
        count: count || data.length,
      });

      if (data) {
        setListLinksUser((prev) => [
          ...data.map(({ id, attributes }) => ({
            id: id,
            createDate: attributes.updated_at,
            expirationDate: attributes.expires_at,
            amount: attributes.amount,
            status: attributes.status,
            link: attributes.vita_url,
            currency: attributes.country.default_currency.iso_code,
          })),
        ]);
      }
    } catch (error) {}
  };

  const handleChangePageLinks = (e, page) => {
    setPageList({
      ...pageList,
      page: page,
    });
    getLinksPayment({ page });
  };

  const handleChangeRowsPerPageLinks = (e) => {
    setPageList({
      ...pageList,
      count: e.target.value,
      page: 0,
    });
    getLinksPayment({ count: e.target.value });
  };

  const hadleCopyLink = (link) => modalCopyValues(link, successMessage);

  // 1 Datos generales
  useEffect(() => {
    (async () => {
      const isAllowed = isAccess('show_business_payment_order_config')
      if (!isAllowed) errorMessage('No tienes permisos para acceder a esta sección')

      try {
        const {
          data: {
            data: { attributes },
          },
        } = await UserProvider.getInfoPaymentLinkUserBusiness(id);

        if (attributes) {
          setHasInfo(true);
          const {
            payment_order_notification_webhook_url,
            logotype_image,
            payment_order_expiration_time,
            available_payment_order_expiration_times,
            payment_order_currency_destiny,
            assigned_payment_order_providers,
            available_payment_order_providers,
          } = attributes;

          setconfigUser({
            ...configUser,
            webHook: payment_order_notification_webhook_url,
            logoCompany: logotype_image,
            timePaymentLink: payment_order_expiration_time,
            timePaymentList: available_payment_order_expiration_times,
            currencyPayment:
              payment_order_currency_destiny?.iso_code.toLowerCase(),
            CurrencyPaymentList: Object.keys(balances),
          });

          // assigned_payment_order_providers
          const countries = Object.keys(assigned_payment_order_providers);
          const infoProviders = [];

          countries.forEach((country) =>
            infoProviders.push(
              ...assigned_payment_order_providers[country].map((provider) => ({
                country: country,
                id: provider.id,
                spread: provider.spread,
                fixed_cost: provider.fixed_cost,
                nameProvider: provider.code,
              }))
            )
          );

          setListProvidersActives([...infoProviders]);

          // available_payment_order_providers
          const newsProviders = Object.keys(available_payment_order_providers);

          if (newsProviders.length === 0) {
            setdisableButton(true);
          } else {
            setProvidersAvalaible(available_payment_order_providers);

            getCountriesAvailable(
              newsProviders,
              available_payment_order_providers
            );
          }
        }
      } catch (error) {}
    })();
  }, [id]);

  // 4 Enlaces generados
  useEffect(() => {
    (async () => {
      try {
        getLinksPayment({ page: pageList.page, count: pageList.count });
      } catch {}
    })();
  }, []);

  if (configUser.status !== payment_orders_service_status) {
    setconfigUser({
      ...configUser,
      status: payment_orders_service_status,
    });
  }

  return {
    configUser,
    configUserUpdate,
    dataProvider,
    disableButton,
    hadleCopyLink,
    handleAssignNewProvider,
    handleChangePageLinks,
    handleChangeRowsPerPageLinks,
    handleCloseModal,
    handleCloseModalInfo,
    handleDeleteProvider,
    handleDownloadLogoUser,
    handleOnchangeConfigUser,
    handleOnchangeItemUpdate,
    handleOpenModal,
    handleShowImage,
    handleUpdateConfigUser,
    handleUpdateProviderActive,
    handleUpdateProviderItemUpdate,
    hasInfo,
    image_design,
    isAccess,
    isOpenModal,
    isOpenModalInfo,
    isUpdate,
    listLinksUser,
    listProvidersActives,
    pageList,
    providerUpdate,
    setImage_design,
    updateConfigUser,
  };
};
export default useUserBusinessLink;
